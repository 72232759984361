@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .mask-image {
    -webkit-mask-image: url("../src/images/bgimage.png");
    -webkit-mask-size: cover;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-image: url("../src/images/bgimage.png");
    mask-size: cover;
    mask-position: center;
    mask-repeat: no-repeat;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
